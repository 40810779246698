import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NavigationService, StateService } from '../../core';
import { MetaService } from './meta.service';
import { UserService } from './user.service';

/**
 * Page Service
 *
 * Bottleneck for our common services that we use inside page wrappers
 */
@Injectable({
	providedIn: 'root'
})
export class PageService {
	/**
	 * Constructor
	 */
	constructor(
		/**
		 * Navigation Service Ref
		 */
		public readonly navigationService: NavigationService,
		/**
		 * State Service Ref
		 */
		public readonly stateService: StateService,
		/**
		 * Toastr Service Ref
		 */
		public readonly toastrService: ToastrService,
		/**
		 * Translate Service Ref
		 */
		public readonly translateService: TranslateService,
		/**
		 * Meta Service Ref
		 */
		public readonly metaService: MetaService,
		// INFO: If used in modal, it may cause injection error (eg. dataService)
		public readonly bsModalService: BsModalService,
		/**
		 * Activated Route Ref
		 */
		public readonly activatedRoute: ActivatedRoute,
		/**
		 * User Service
		 */
		public readonly userService: UserService
	) {}
}
