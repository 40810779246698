import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LuIconComponent } from './lu-icon.component';

@NgModule({
	declarations: [LuIconComponent],
	imports: [CommonModule],
	exports: [LuIconComponent]
})
export class LuIconModule {}
