import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UploadFileType } from 'src/app/globals';
import { BaseResponse, DsConfig } from '../core.models';
import { DataService } from './data.service';

/** Represents a signature used to upload a file to S3 */
interface S3Signature {
	file_name?: string;
	file_path_and_name_override?: string;
	folder_path: string;
	guid: string;
	key: string;
	policy: string;
	x_amz_signature: string;
	x_amz_credential: string;
	x_amz_date: string;
	x_amz_algorithm: string;
	x_amz_storage_class?: string;
	success_action_redirect?: string;
	upload_url: string;
	resource_id: string;
}

export interface S3UploadInterface {
	fileType: UploadFileType;
	uploadImageLocation: DsConfig;
	sendImageLocationData: (data) => any;
	signatureData?: any;
	onPoolEmit?: (data) => any;
	passProgress?: (state: S3UploadProgress) => void;
	dsPoolingConfig?: DsConfig;
	poolingUrl?: (key) => string;
	useNewPooling?: boolean;
	skipPooling?: boolean;
	file?: File;
	files?: File[];
}

export enum S3UploadProgress {
	FILE_UPLOADED = 'S3_FILE_UPLOADED',
	FILE_UPLOAD_FAILED = 'S3_FILE_UPLOAD_FAILED',
	SENT_TO_BACKEND = 'S3_SENT_TO_BACKEND',
	BACKEND_COMPLETE = 'S3_BACKEND_COMPLETE'
}

type DataFormatter<T> = (data: T) => S3UploadResponse;

export interface S3UploadResponse {
	[key: string]: string | {};
}

/**
 * Contains common functions for accessing Amazon S3
 */
@Injectable()
export class S3Service {
	constructor(private readonly dataService: DataService) {}

	/**
	 * Given a file and LearnUpon file type, will upload that file to S3 and submit result to BE.
	 * During upload will emit HttpEvents, allowing for UI loading bar implementations.
	 */
	public uploadToS3(config: S3UploadInterface): Observable<BaseResponse<any> | any> {
		return this.uploadFile(config.file, config).pipe(
			switchMap(responseBody =>
				responseBody
					? this.sendFileLocation(responseBody, config.uploadImageLocation, config.sendImageLocationData)
					: of({} as BaseResponse<S3UploadResponse>)
			),
			tap(() => this.executePassProgress(config, S3UploadProgress.SENT_TO_BACKEND)),
			switchMap(response => this.handlePooling(config, response)),
			tap(() => this.executePassProgress(config, S3UploadProgress.BACKEND_COMPLETE))
		);
	}

	/**
	 * Given a files and LearnUpon file type, will upload all files to S3 and submit results to BE.
	 * During upload will emit HttpEvents, allowing for UI loading bar implementations.
	 */
	public uploadToS3InBulk(config: S3UploadInterface): Observable<BaseResponse<any> | any> {
		if (!config?.files?.length) return;
		const uploadObservables$ = config.files.map(file => this.uploadFile(file, config));

		return forkJoin(uploadObservables$).pipe(
			switchMap(responseBodies => {
				return responseBodies.some(Boolean)
					? this.sendFileLocationInBulk(responseBodies, config.uploadImageLocation, config.sendImageLocationData)
					: of({} as BaseResponse<S3UploadResponse>);
			}),
			tap(() => this.executePassProgress(config, S3UploadProgress.SENT_TO_BACKEND)),
			switchMap(response => this.handlePooling(config, response)),
			tap(() => this.executePassProgress(config, S3UploadProgress.BACKEND_COMPLETE))
		);
	}

	private S3_UPLOAD_SIGNATURE = (fileType: number | string): string => `/angie/uploads/s3-upload-for/${fileType}.json`;

	/**
	 * Executes the passProgress method on an instance of S3UploadInterface, if it exists
	 * @param config
	 * @param progress string to pass to `passProgress` method
	 */
	private executePassProgress(config: S3UploadInterface, progress: S3UploadProgress): void {
		config.passProgress && config.passProgress(progress);
	}

	/**
	 * Performs file upload to S3 after getting correlated signature from BE
	 * @param file
	 * @param config
	 */
	private uploadFile(file: File, config: S3UploadInterface): Observable<string> {
		return this.getS3Signature(config.fileType, config.signatureData).pipe(
			switchMap(signature => this.performS3Upload(signature, this.createS3PostForm(file, signature))),
			tap(() => this.executePassProgress(config, S3UploadProgress.FILE_UPLOADED)),
			catchError(() => {
				this.executePassProgress(config, S3UploadProgress.FILE_UPLOAD_FAILED);
				return of(null); // returning null to keep the flow going
			})
		);
	}

	/**
	 * Gets a generated S3 signature from server to sign the upload with
	 */
	private getS3Signature(fileType: UploadFileType, getSignatureData?: any): Observable<S3Signature> {
		const cfg = this.dataService.getDefaultConfig(this.S3_UPLOAD_SIGNATURE(fileType));
		cfg.primarySpinnerConfig = { show: false };
		return this.dataService.post<S3Signature>(cfg, getSignatureData).pipe(
			map(res => (res as any).signature_data.attributes) // TOD: this mapping needs match ne BE signature -> res.data
		);
	}

	/**
	 * Given a file and S3 signature, will generate a POST form to be sent to the S3 server.
	 */
	private createS3PostForm(file: File, signature: S3Signature): FormData {
		let fullPath = signature.folder_path + file.name;
		if (!!signature.file_path_and_name_override) {
			fullPath = signature.file_path_and_name_override;
		}

		const formData = new FormData();
		// using append since set is not available in IE11 https://developer.mozilla.org/en-US/docs/Web/API/FormData/set#browser_compatibility
		// since we do create new formData here we're safe
		formData.append('key', fullPath);
		// formData.append('AWSAccessKeyId', signature.key);
		formData.append('acl', 'public-read');
		formData.append('success_action_status', '201');
		formData.append('policy', signature.policy);
		formData.append('X-Amz-Signature', signature.x_amz_signature);
		formData.append('X-Amz-Credential', signature.x_amz_credential);
		formData.append('X-Amz-Date', signature.x_amz_date);
		formData.append('X-Amz-Algorithm', signature.x_amz_algorithm);
		formData.append('X-Amz-Storage-Class', signature.x_amz_storage_class);
		formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
		formData.append('filename', fullPath);
		formData.append('file', file);

		return formData;
	}

	/**
	 * Sends a POST request to the Amazon S3 endpoint, uploading the file
	 */
	private performS3Upload(signature: S3Signature, formData: FormData): Observable<string> {
		const cfg = this.dataService.getDefaultConfig(signature.upload_url);
		cfg.primarySpinnerConfig = { show: false };
		return this.dataService.uploadFile(cfg, formData);
	}

	/**
	 * On success upload, send file location to BE and ping for progress
	 */
	private sendFileLocation(
		responseBody: string,
		cfg: DsConfig,
		dataFormatter: DataFormatter<S3UploadResponse>
	): Observable<BaseResponse<S3UploadResponse>> {
		const uploadResponse = this.parseS3responseBody(responseBody);
		const cfgData = dataFormatter(uploadResponse);
		cfg.primarySpinnerConfig = { show: false };
		return this.dataService.post<S3UploadResponse>(cfg, cfgData);
	}

	/**
	 * On success upload, send files location in bulk to BE and ping for progress
	 */
	private sendFileLocationInBulk(
		responses: string[],
		cfg: DsConfig,
		dataFormatter: DataFormatter<S3UploadResponse[]>
	): Observable<BaseResponse<S3UploadResponse>> {
		const uploadResponses = responses.map(response => this.parseS3responseBody(response));
		const cfgData = dataFormatter(uploadResponses);
		cfg.primarySpinnerConfig = { show: false };
		return this.dataService.post<S3UploadResponse>(cfg, cfgData);
	}

	/**
	 * Parsing S3 response to an object
	 * @param responseBody
	 */
	private parseS3responseBody(responseBody: string): S3UploadResponse {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(responseBody, 'text/xml');

		const configData = {};
		const responseContent = xmlDoc.childNodes[0].childNodes;

		for (let i = 0; i < responseContent.length; i++) {
			configData[(responseContent[i] as any).tagName] = responseContent[i].textContent;
		}

		return configData;
	}

	/**
	 * In case it is required we are performing pooling on after uploaded files are submitted to BE
	 * @param config
	 * @param response
	 */
	private handlePooling(config: S3UploadInterface, response: BaseResponse<S3UploadResponse>): Observable<any> {
		if (config.skipPooling) {
			return of(response);
		}
		const { key } = response?.data;
		const poolingConfig = config.dsPoolingConfig || this.dataService.getDefaultConfig(config.poolingUrl(key));
		poolingConfig.primarySpinnerConfig.show = false;
		return config.useNewPooling
			? this.dataService.doNewPooling(poolingConfig)
			: this.dataService.doPooling(poolingConfig);
	}
}
