import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isArray, startCase } from 'lodash-es';
import { DataService as SharedDataService } from 'lu-services';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, timer } from 'rxjs';
import { catchError, finalize, map, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { AngieAppRoutes } from 'src/app/angie-app.routes';
import {
	AngieClientAction,
	BackgroundJob,
	BackgroundJobResponse,
	BackgroundJobStatus,
	BaseModel,
	BaseResponse,
	DsConfig,
	ListResponse,
	StageChange
} from '../core.models';
import { SpinnerService } from './spinner.service';
import { WindowRefService } from './window-ref.service';

/**
 * Data Service - inherits SharedDataService (lu-services)
 *
 * this is our core bottleneck service which serves as communication with our REST
 *
 * We inherit existing methods and add additional properties to stream
 */
@Injectable()
export class DataService extends SharedDataService {
	/**
	 * State Change - introduced here as subject which will emit some state change if happen inside our app on BE
	 *
	 * each request will contain state_change inside it's BaseResponse and this checks for it to see if we have some changes
	 *
	 * if this emits, we'll update our UI (fetch new data and update state as example)
	 */
	stateChangeSubject$ = new Subject<StageChange>();
	/**
	 * State Change as observable - probably not needed since Subject is Observable already
	 */
	stateChange$: Observable<StageChange> = this.stateChangeSubject$.asObservable();

	/**
	 * Constructor
	 */
	constructor(
		httpClient: HttpClient,
		spin: SpinnerService,
		toastrService: ToastrService,
		private readonly translateService: TranslateService,
		private readonly router: Router,
		private readonly windowRefService: WindowRefService
	) {
		super(httpClient, spin, toastrService);
	}

	/**
	 * Do Pooling
	 *
	 * Method that has timer and sends requests all  the time to check for some progresses until these are COMPLETED.
	 *
	 * If data has error, it should show toastr.
	 *
	 * This is long pooling for now but later at some stage we can extend this one with websockets
	 */
	doNewPooling(cfg: DsConfig, repeatInMs: number = 500, hideToastrMsg?: boolean): Observable<any> {
		return timer(0, repeatInMs).pipe(
			mergeMap(() => this.get<BackgroundJobResponse>(cfg)),
			map((response: any) => {
				const { job, data } = response;

				const backgroundJob = job || data;

				if (backgroundJob?.has_errors && !hideToastrMsg) {
					this.toastrService.error(backgroundJob?.flash_msg);
				}

				return backgroundJob;
			}),
			takeWhile(
				backgroundJob =>
					![BackgroundJobStatus.COMPLETED, BackgroundJobStatus.FAILED].includes(backgroundJob?.status_id),
				true
			),
			finalize(() => {
				this.handleStop(cfg);
			})
		);
	}

	doPooling(cfg: DsConfig, repeatInMs: number = 500, hideToastrMsg?: boolean): Observable<any> {
		return timer(0, repeatInMs).pipe(
			mergeMap(() => this.get<BackgroundJob>(cfg)),
			tap((response: BaseResponse<BackgroundJob>) => {
				const { data } = response; // this is bad way of handling response on pooling if we want it in toastr as generics
				if (data.has_errors) {
					hideToastrMsg ? null : this.toastrService.error(data.flash_msg);
				}
			}),
			takeWhile((data: BaseResponse<BackgroundJob>) => {
				const statuses = [BackgroundJobStatus.COMPLETED, BackgroundJobStatus.FAILED];
				return !statuses.includes(data.data.status_id);
			}, true),
			finalize(() => {
				this.handleStop(cfg);
			})
		);
	}

	/**
	 * Post
	 *
	 * Extends our base class post and adds checkStateChange inside stream
	 */
	post<T extends BaseModel>(cfg: DsConfig, data: T): Observable<BaseResponse<T>> {
		return super.post(cfg, data).pipe(tap(response => this.checkStateChange<T>(response)));
	}

	/**
	 * Post
	 *
	 * Extends our base class patch and adds checkStateChange inside stream
	 */
	patch<T extends BaseModel>(cfg: DsConfig, data: T): Observable<BaseResponse<T>> {
		return super.patch(cfg, data).pipe(tap(response => this.checkStateChange<T>(response)));
	}

	/**
	 * Put
	 *
	 * Extends our base class post and adds checkStateChange inside stream
	 */
	put<T extends BaseModel>(cfg: DsConfig, data: T): Observable<BaseResponse<T>> {
		return super.put(cfg, data).pipe(tap(response => this.checkStateChange<T>(response)));
	}

	/**
	 * PutTK
	 *
	 * Extends our base class post and adds checkStateChange inside stream
	 */
	putTK<T, K>(cfg: DsConfig, data: T): Observable<BaseResponse<K>> {
		return super.putTK<T, K>(cfg, data).pipe(tap(response => this.checkStateChange(response)));
	}

	/**
	 * Get List
	 *
	 * Extends our base class post and adds checkStateChange inside stream
	 */
	getList<T>(cfg: DsConfig): Observable<ListResponse<T>> {
		return super.getList<T>(cfg).pipe(tap(response => this.checkStateChange<T>(response)));
	}

	/**
	 * Get
	 *
	 * Extends our base class post and adds checkStateChange inside stream
	 */
	get<T>(cfg: DsConfig): Observable<BaseResponse<T>> {
		return super.get<T>(cfg).pipe(tap(response => this.checkStateChange<T>(response)));
	}

	/**
	 * Delete
	 *
	 * Extends our base class post and adds checkStateChange inside stream
	 */
	delete<T extends BaseModel>(cfg: DsConfig, data?: T): Observable<BaseResponse<T>> {
		return super.delete(cfg, data).pipe(tap(response => this.checkStateChange<T>(response)));
	}

	/**
	 * Upload file
	 * @param cfg our data service config
	 * @param data data to get sent, extends FormData
	 */
	uploadFile<T extends FormData>(cfg: DsConfig, data: T): Observable<string> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient
			.post<BaseResponse<T>>(cfg.url, data, {
				responseType: 'text' as 'json',
				reportProgress: true
			})
			.pipe(
				catchError((err: HttpErrorResponse) => {
					hasError = true;
					return this.handleError(err, cfg);
				}),
				finalize(() => {
					this.handleStop(cfg, hasError);
				})
			);
	}

	/**
	 * Set toastr titles
	 */
	setToastrTitles(): any {
		return {
			success: this.translateService.instant('data_service.toastr.success.success'),
			unauthorized: this.translateService.instant('data_service.toastr.error.unauthorized'),
			forbidden: this.translateService.instant('data_service.toastr.error.forbidden'),
			not_found: this.translateService.instant('data_service.toastr.error.not_found'),
			internal: this.translateService.instant('data_service.toastr.error.internal'),
			error: this.translateService.instant('data_service.toastr.error.error')
		};
	}

	/**
	 * HandleError
	 *
	 * If error had been caught it will get into this function
	 *
	 * It either triggers toastrService and shows error
	 */
	handleError(err: HttpErrorResponse, cfg: DsConfig): Observable<any> {
		if (cfg.skipErrorHandling) {
			throw err;
		}
		if (cfg.popupMessageConfig && cfg.popupMessageConfig.failMessage) {
			let { title, message } = cfg.popupMessageConfig.failMessage;

			// if we have custom errors returned from BE
			if (!!err.error.error && isArray(err.error.error)) {
				// with response error body
				const errorArray = err.error.error;
				errorArray.forEach(error => {
					let errorMessage = this.translateService.instant(error.value, error.keys);
					errorMessage = error.attribute ? `${startCase(error.attribute)} ${errorMessage}` : errorMessage;
					this.toastrService.error(errorMessage, title);
				});
			} else {
				// Handle our generic errors 401/500 etc.
				title = title ? title : this.errorTitleSet(title, err.status);
				// this one will handle basic errors - 500/401
				message = message ? message : err.statusText;
				this.toastrService.error(message, title);
			}

			this.hasError = true;
		}

		if (err.status === 401) {
			this.handle401();
		}

		if (err.status === 403) {
			this.handle403();
		}

		throw err;
	}

	/**
	 * If user is not logged in or session expired
	 */
	private handle401(): void {
		if (this.signInPage()) {
			return;
		}
		const redirectUrl = `${AngieAppRoutes.SIGN_IN}?next=${encodeURIComponent(
			this.windowRefService.getWindow().location.pathname
		)}`;
		this.windowRefService.getWindow().location.href = redirectUrl;
	}

	/**
	 * Check if we are on sign in page(s) already
	 */
	private signInPage(): boolean {
		return (
			window.location.pathname === AngieAppRoutes.SIGN_IN ||
			window.location.pathname.match(/\/embed\/courses\/\d+/) != null
		);
	}

	/**
	 * If user is not not allowed to see resource
	 */
	private handle403(): void {
		this.router.navigate(['forbidden']);
		// we would need to handle reload here but also add rails route in this case cause maybe state has been changed in BE
		// this.windowRefService.getWindow().location.reload();
	}

	/**
	 * Check State change
	 *
	 * Emit value to our subject if there are any state changes
	 */
	private checkStateChange<T>(response: ListResponse<T> | BaseResponse<T>): void {
		if (response && !!response.state_change) {
			Object.keys(response.state_change).forEach(key => {
				this.stateChangeSubject$.next({
					action: key as unknown as AngieClientAction,
					payload: response.state_change[key]
				});
			});
		}
	}
}
