import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class WindowRefService {
	isBrowser: boolean;

	constructor(@Inject(PLATFORM_ID) public platformId) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	get nativeWindow(): any {
		if (this.isBrowser) {
			return this.getWindow();
		}

		return null;
	}

	getWindow(): any {
		return window;
	}
}
