import { PageMeta } from '../insights/insights.models';

export const LEARNING_JOURNEY_LIST_PAGE_META: Record<string, PageMeta> = {
	'^/learning-journey$': {
		pagegroup: 'learning-journey',
		pagename: 'admin-learning-journey-overview'
	}
};

export const LEARNING_JOURNEY_EDIT_PAGE_META: Record<string, PageMeta> = {
	'^/learning-journey/.+/.+/edit': {
		pagegroup: 'learning-journey',
		pagename: 'learning-journey-canvas'
	}
};

export const LEARNING_JOURNEY_ENROLLMENTS_PAGE_META: Record<string, PageMeta> = {
	'^/learning-journey/.+/.+/enrollments': {
		pagegroup: 'learning-journey',
		pagename: 'learning-journey-learner-activity'
	}
};
