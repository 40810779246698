import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';

/**
 * Primary spinner
 *
 * Sits inside our layout and shown/hidden on demand through service
 */
@Component({
	selector: 'angie-primary-spinner',
	templateUrl: './primary-spinner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimarySpinnerComponent extends BaseComponent implements OnInit {
	/**
	 * Flag that indicates whether we should show or hide spinner
	 */
	show: boolean;
	/**
	 * CDNPath for having spinner shown with path inside our html
	 */
	cdnPath: string = environment.cdnBase;

	/**
	 * Constructor
	 */
	constructor(
		private readonly spinnerService: SpinnerService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}

	/**
	 * OnLifeCycle hook
	 */
	ngOnInit(): void {
		this.spinnerService.primarySpinner$.pipe(takeUntil(this.destroy$)).subscribe(show => {
			this.show = show;
			this.changeDetectorRef.detectChanges();
		});
	}
}
