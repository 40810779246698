import { LayoutType } from 'src/app/core';
import { LayoutConfigBuilder } from 'src/app/layouts/default-layout/builders/layout-config.builder';
import { LayoutConfigPresets } from 'src/app/layouts/default-layout/models/layout-structures.models';

export enum LiveLearningRoutesConfig {
	LIVE_LEARNING_BASE_URL = 'live-learning'
}

export const pageLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.default)
	.setBodyCssClasses(['bg--white'])
	.showDefaultSubHeader(false)
	.setContentCssClasses(['wrap--no-subheader'])
	.setHideAdminNav(true)
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.build();

export const layoutConfig = { layout: LayoutType.LARGE, layoutConfig: pageLayoutConfig };

export const LiveLearningRouteTitles = {
	liveLearningHomePage: 'events.live_learning_event_list_page_title'
};
