import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { BaseModel, BaseResponse, DsConfig, ListResponse, PrimarySpinnerConfig } from '../models/core.models';
import { SpinnerService } from './spinner.service';

/**
 * Our main bottlneck service - in charge for handling all of our HTTP requests
 */
@Injectable()
export class DataService {
	/**
	 * Message title map when we return from BE generic responses
	 */
	responseMsgTitle = {
		success: 'Success',
		unauthorized: 'Unauthorized',
		forbidden: 'Forbidden',
		not_found: 'Resource Not Found',
		internal: 'Internal Server Error',
		error: 'Error'
	};
	/**
	 * Has Error flag to check if our requests caught some errors
	 */
	hasError: boolean;

	/**
	 * Constructor
	 */
	constructor(
		public httpClient: HttpClient,
		public spin: SpinnerService,
		public toastrService: ToastrService
	) {}

	/**
	 * Get default configuration used for data service config
	 */
	getDefaultConfig(url: string, queryParamModel?: any): DsConfig {
		if (queryParamModel) {
			url = url.concat('?' + this.createUrlParams(queryParamModel));
		}
		return {
			url: url,
			primarySpinnerConfig: {
				show: true
			}
		};
	}

	/**
	 * Get request with some default success/fail messages that we'd pass
	 */
	getDefaultWithMessage(url: string, message?: string, failMessage?: string, showSpinner: boolean = true): DsConfig {
		const dsConfig: DsConfig = {
			url: url,
			primarySpinnerConfig: {
				show: showSpinner
			},
			popupMessageConfig: {}
		};

		if (message) {
			dsConfig.popupMessageConfig.successMessage = {
				message: message
			};
		}

		if (failMessage) {
			dsConfig.popupMessageConfig.failMessage = {
				message: failMessage
			};
		}
		return dsConfig;
	}

	/**
	 * Create url params based on some model that is passed
	 */
	createUrlParams(model: any): string {
		return Object.keys(model)
			.map(key => {
				if (key in model && model[key] !== undefined && model[key] !== null) {
					return key + '=' + encodeURIComponent(model[key]);
				}
			})
			.filter(value => value !== null && value !== undefined)
			.join('&');
	}

	/**
	 * Post request - usually inserts new data as record
	 */
	post<T extends BaseModel>(cfg: DsConfig, data: T): Observable<BaseResponse<T>> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient.post<BaseResponse<T>>(cfg.url, data).pipe(
			catchError((err: HttpErrorResponse) => {
				hasError = true;
				return this.handleError(err, cfg);
			}),
			finalize(() => {
				this.handleStop(cfg, hasError);
			})
		);
	}

	/**
	 * Put request - updates existing record
	 */
	put<T extends BaseModel>(cfg: DsConfig, data: T): Observable<BaseResponse<T>> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient.put<BaseResponse<T>>(cfg.url, data).pipe(
			catchError((err: HttpErrorResponse) => {
				hasError = true;
				return this.handleError(err, cfg);
			}),
			finalize(() => {
				this.handleStop(cfg, hasError);
			})
		);
	}

	/**
	 * Patch request - partially updates existing record
	 */
	patch<T extends BaseModel>(cfg: DsConfig, data: T): Observable<BaseResponse<T>> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient.patch<BaseResponse<T>>(cfg.url, data).pipe(
			catchError((err: HttpErrorResponse) => {
				hasError = true;
				return this.handleError(err, cfg);
			}),
			finalize(() => {
				this.handleStop(cfg, hasError);
			})
		);
	}

	// TODO figure if we need tuple put
	putTK<T, K>(cfg: DsConfig, data: T): Observable<BaseResponse<K>> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient.put<BaseResponse<K>>(cfg.url, data).pipe(
			catchError((err: HttpErrorResponse) => {
				hasError = true;
				return this.handleError(err, cfg);
			}),
			finalize(() => {
				this.handleStop(cfg, hasError);
			})
		);
	}

	/**
	 * Get list from BE - usually used when having pagination or infinite scroll
	 */
	getList<T>(cfg: DsConfig): Observable<ListResponse<T>> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient.get<ListResponse<T>>(cfg.url).pipe(
			catchError((err: HttpErrorResponse) => {
				hasError = true;
				return this.handleError(err, cfg);
			}),
			finalize(() => {
				this.handleStop(cfg, hasError);
			})
		);
	}

	/**
	 * Get request
	 */
	get<T>(cfg: DsConfig): Observable<BaseResponse<T>> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient.get<BaseResponse<T>>(cfg.url).pipe(
			catchError((err: HttpErrorResponse) => {
				hasError = true;
				return this.handleError(err, cfg);
			}),
			finalize(() => {
				this.handleStop(cfg, hasError);
			})
		);
	}

	/**
	 * Delete record
	 */
	delete<T extends BaseModel>(cfg: DsConfig, data?: T): Observable<BaseResponse<T>> {
		let hasError = false;
		this.handleStart(cfg.primarySpinnerConfig);
		return this.httpClient.delete<BaseResponse<T>>(cfg.url, data).pipe(
			catchError((err: HttpErrorResponse) => {
				hasError = true;
				return this.handleError(err, cfg);
			}),
			finalize(() => {
				this.handleStop(cfg, hasError);
			})
		);
	}

	/**
	 * Handle start is function that defines very beginning of an request
	 */
	handleStart(primarySpinnerConfig: PrimarySpinnerConfig): void {
		if (primarySpinnerConfig.show) {
			this.spin.show();
		}
	}

	/**
	 * If error occurs - we could
	 */
	handleError(err: HttpErrorResponse, cfg: DsConfig): Observable<any> {
		if (cfg.popupMessageConfig?.failMessage && !cfg.skipErrorHandling) {
			cfg.popupMessageConfig.failMessage.title = this.errorTitleSet(
				cfg.popupMessageConfig.failMessage.title,
				err.status
			);
			cfg.popupMessageConfig.failMessage.message = cfg.popupMessageConfig.failMessage.message || err.error.error;
			this.toastrService.error(cfg.popupMessageConfig.failMessage.message, cfg.popupMessageConfig.failMessage.title);
		}

		throw err;
	}

	/**
	 * Set error title for toastr
	 */
	errorTitleSet(title: string | null, status): string {
		switch (status) {
			case 401: // user is not not logged in or has invalid session
				return this.responseMsgTitle.unauthorized;
			case 403: // user is authorized but not allowed to view resource
				return this.responseMsgTitle.forbidden;
			case 404: // no resource found
				return this.responseMsgTitle.not_found;
			case 500:
				return this.responseMsgTitle.internal;
			default:
				return this.responseMsgTitle.error;
		}
	}

	/**
	 * Once request has been finished we'd like to call this function to control behaviour from here
	 */
	handleStop(cfg: DsConfig, hasError: boolean = false): void {
		const { primarySpinnerConfig, popupMessageConfig } = cfg;
		if (primarySpinnerConfig.show) {
			this.spin.hide();
		}

		if (popupMessageConfig && popupMessageConfig.successMessage && !hasError) {
			let title = this.responseMsgTitle.success;
			if (popupMessageConfig.successMessage.title) {
				title = popupMessageConfig.successMessage.title;
			}
			this.toastrService.success(popupMessageConfig.successMessage.message, title);
		}
	}
}
