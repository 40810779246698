import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SpinnerService {
	spinnerSubject$ = new Subject<boolean>();
	primarySpinnerCount = 0;
	primarySpinner$: Observable<boolean> = this.spinnerSubject$.asObservable();

	constructor() {}

	show(): void {
		this.primarySpinnerCount++;
		if (this.primarySpinnerCount === 1) {
			this.spinnerSubject$.next(true);
		}
	}

	hide(): void {
		this.primarySpinnerCount--;
		if (this.primarySpinnerCount <= 0) {
			this.primarySpinnerCount = 0;
			setTimeout(() => {
				this.spinnerSubject$.next(false);
			}, 500);
		}
	}
}
