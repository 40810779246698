import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngieAppRoutes } from './angie-app.routes';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';

const authRoutes = [
	{
		path: `${AngieAppRoutes.AUTH}/sign_in`,
		loadChildren: () => import('./modules/auth/pages/sign-in/sign-in.module').then(m => m.SignInModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/sign_up`,
		loadChildren: () => import('./modules/auth/pages/sign-up/sign-up.module').then(m => m.SignUpModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/new`,
		loadChildren: () =>
			import('./modules/auth/pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/change_password_first`,
		loadChildren: () =>
			import('./modules/auth/pages/change-password/change-password.module').then(m => m.ChangePasswordModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/edit`,
		loadChildren: () =>
			import('./modules/auth/pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/default/edit`,
		loadChildren: () =>
			import('./modules/auth/pages/reset-default-password/reset-default-password.module').then(
				m => m.ResetDefaultPasswordModule
			)
	},
	{
		path: `${AngieAppRoutes.AUTH}/confirmation`,
		loadChildren: () => import('./modules/auth/pages/confirmation/confirmation.module').then(m => m.ConfirmationModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/code_redeem`,
		loadChildren: () => import('./modules/auth/pages/code-redeem/code-redeem.module').then(m => m.CodeRedeemModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/terms_of_service`,
		loadChildren: () =>
			import('./modules/terms-and-service/terms-and-service.module').then(m => m.TermsAndServiceModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/privacy-policy`,
		loadChildren: () =>
			import('./modules/auth/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
	}
];

/**
 * Routes schema of our app routing module - Root Level routes
 */
const routes = [
	...authRoutes,
	{
		path: `embed`,
		loadChildren: () => import('./modules/auth/pages/embed/embed.module').then(m => m.EmbedModule)
	},
	{
		path: AngieAppRoutes.CATALOG,
		loadChildren: () => import('./modules/catalog/catalog.module').then(m => m.CatalogModule)
	},
	{
		path: '',
		loadChildren: () => import('./layouts/layout.module').then(m => m.LayoutModule)
	},
	{
		path: AngieAppRoutes.OAUTH,
		component: EmptyLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./modules/public-oauth/public-oauth.module').then(m => m.PublicOauthModule)
			}
		]
	}

	// {
	// 	path: '**',
	// 	redirectTo: `/${ AngieAppRoutes.DASHBOARD }`
	// },
];

/**
 * App Routing routes definition - Define all App root level routing here
 */
@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// enable router debug
			enableTracing: false,
			useHash: false,
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
