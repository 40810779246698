import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActionFooterItem } from '../models/core.models';

@Injectable()
export class ActionFooterService {
	footerActions$ = new BehaviorSubject<ActionFooterItem[]>([]);

	public static DEFAULT_FORM_ACTIONS(successCallback: () => void, failureCallback: () => void): ActionFooterItem[] {
		return [
			{
				buttonClass: 'btn btn-success',
				label: 'Save',
				onClick: successCallback
			},
			{
				buttonClass: 'btn btn-danger',
				label: 'Cancel',
				onClick: failureCallback
			}
		];
	}

	getFooterActions(): BehaviorSubject<ActionFooterItem[]> {
		return this.footerActions$;
	}

	populateFooterActions(actions: ActionFooterItem[]): void {
		this.footerActions$.next(actions);
	}

	clearFooterActions(): void {
		this.footerActions$.next([]);
	}
}
