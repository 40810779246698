import { ElementRef, Injectable, inject } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WindowRefService } from './window-ref.service';

export interface WindowScroll {
	top: number;
}

@Injectable({
	providedIn: 'root'
})
export class ScrollService {
	window = inject(WindowRefService).nativeWindow;
	scrollObservable$: Observable<WindowScroll>;

	constructor() {
		this.scrollObservable$ = fromEvent(this.window, 'scroll').pipe(
			startWith(this.calculateStartPosition()),
			map(() => {
				return {
					top: this.window.pageYOffset
				};
			})
		);
	}

	calculateStartPosition(): WindowScroll {
		return {
			top: this.window.pageYOffset
		};
	}

	keyScrollUp(htmlContainer: ElementRef, index: number): boolean {
		const element = htmlContainer.nativeElement.querySelector('ul li:nth-child(' + index + ')');
		if (!element) {
			return false;
		}

		const container = htmlContainer.nativeElement.getBoundingClientRect();
		const elementPosition = element.getBoundingClientRect();
		const itemTopOffset = elementPosition.top;
		const containerTopOffset = container.top;

		if (containerTopOffset > itemTopOffset) {
			htmlContainer.nativeElement.scrollTop -= containerTopOffset - itemTopOffset;
		}

		return true;
	}

	keyScrollDown(htmlContainer: ElementRef, index: number): boolean {
		const element = htmlContainer.nativeElement.querySelector('ul li:nth-child(' + index + ')');
		if (!element) {
			return false;
		}

		const container = htmlContainer.nativeElement.getBoundingClientRect();
		const elementPosition = element.getBoundingClientRect();

		const itemBottomOffset = elementPosition.bottom;
		const boxBottomOffset = container.bottom;

		if (itemBottomOffset > boxBottomOffset) {
			htmlContainer.nativeElement.scrollTop += itemBottomOffset - boxBottomOffset;
		}

		return true;
	}
}
