import { COURSES_PAGE_META } from '../courses/courses-tracking.config';
import { REPORTS_BUILDER_PAGE_META } from '../reports-builder/reports-builder-tracking.config';
import { PageMeta } from './insights.models';
import {
	MANAGER_DASHBOARD_LANDING_PAGE_META,
	MANAGER_DASHBOARD_LIST_LEARNERS
} from '../dashboard/dashboard-tracking.config';
import {
	LEARNING_JOURNEY_EDIT_PAGE_META,
	LEARNING_JOURNEY_ENROLLMENTS_PAGE_META,
	LEARNING_JOURNEY_LIST_PAGE_META
} from '../learning-journey/learning-journey-tracking.config';
import { LEARNER_DASHBOARD_PAGE_META } from '../learner-dashboard/learner-dashboard-tracking.config';
import { MY_LEARNING_PAGE_META } from '../my-learning/my-learning-tracking.config';
import { LEARNING_JOURNEY_PROGRESSION_PAGE_META } from '../learning-journey-progression/learning-journey-progression-tracking.config';

export const ROUTER_PAGE_META: Record<string, PageMeta> = {
	...COURSES_PAGE_META,
	...REPORTS_BUILDER_PAGE_META,
	...MANAGER_DASHBOARD_LANDING_PAGE_META,
	...MANAGER_DASHBOARD_LIST_LEARNERS,
	...LEARNING_JOURNEY_LIST_PAGE_META,
	...LEARNING_JOURNEY_EDIT_PAGE_META,
	...LEARNING_JOURNEY_ENROLLMENTS_PAGE_META,
	...MANAGER_DASHBOARD_LIST_LEARNERS,
	...LEARNER_DASHBOARD_PAGE_META,
	...MY_LEARNING_PAGE_META,
	...LEARNING_JOURNEY_PROGRESSION_PAGE_META
};
