import { LayoutType } from 'src/app/core';
import { LayoutConfigBuilder } from 'src/app/layouts/default-layout/builders/layout-config.builder';
import { LayoutConfigPresets } from 'src/app/layouts/default-layout/models/layout-structures.models';

export enum LibraryRoutesConfig {
	LIBRARY_BASE_URL = 'library',
	SCORM_TINCAN_SEGMENT = 'scorm-tincan',
	QUESTION_POOLS_SEGMENT = 'question_pools'
}

export const LibraryRouteTitles = {
	scormsAndTincans: 'lup.subnav.lup_library_scorms'
};

export const scormsAndTincansLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.default)
	.setBodyCssClasses(['bg--gray-lighter'])
	.build();

export const dataForScormsAndTincansLayoutConfig = {
	layout: LayoutType.LARGE,
	layoutConfig: scormsAndTincansLayoutConfig,
	breadcrumb: LibraryRouteTitles.scormsAndTincans
};
