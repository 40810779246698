// keycodes
export const UP_ARROW = 38;
export const DOWN_ARROW = 40;
export const RIGHT_ARROW = 39;
export const LEFT_ARROW = 37;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const HOME = 36;
export const END = 35;
export const ENTER = 13;
export const SPACE = 32;
export const F1 = 112;
export const TAB = 9;
export const ESCAPE = 27;
export const BACKSPACE = 8;
export const DELETE = 46;
export const A_WORD = 65;
export const Z_WORD = 90;
export const ZERO = 48;
export const NINE = 91;
export const COMMA = 188;

// event.key
export const KEY_UP_ARROW = 'ArrowUp';
export const KEY_DOWN_ARROW = 'ArrowDown';
export const KEY_RIGHT_ARROW = 'ArrowRight';
export const KEY_LEFT_ARROW = 'ArrowLeft';
export const KEY_PAGE_UP = 'PageUp';
export const KEY_PAGE_DOWN = 'PageDown';
export const KEY_HOME = 'Home';
export const KEY_END = 'End';
export const KEY_ENTER = 'Enter';
export const KEY_SPACE = ' ';
export const KEY_F1 = 'F1';
export const KEY_TAB = 'Tab';
export const KEY_ESCAPE = 'Escape';
export const KEY_BACKSPACE = 'Backspace';
export const KEY_DELETE = 'Delete';
export const KEY_A_WORD = 'a';
export const KEY_Z_WORD = 'z';
export const KEY_ZERO = '0';
export const KEY_NINE = '9';
export const KEY_COMMA = ',';

// Ruby Date Format
export enum DateFormat {
	DAY_MONTH_YEAR = '%d/%m/%Y',
	MONTH_DAY_YEAR = '%m/%d/%Y',
	YEAR_MONTH_DAY = '%Y/%m/%d'
}

/** Variables for ngx-bootrap modal */
export const DISMISS_REASONS = {
	BACKRDOP: 'backdrop-click',
	ESC: 'esc'
};

/** default date-time format */
export const DATE_FORMAT = 'dd-MMM-yyyy';
export const PIPE_DATE_TIME = 'dd-LLL-yyyy';

// Breaking point for mid menu (pills)
export const SCREEN_BREAK_POINT = 1220;

// Debounce Time on TypeAheads
export const TYPE_AHEAD_DEBOUNCE_TIME = 300;

// RegExp
// tslint:disable-next-line
export const EMAIL_REGEX =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
